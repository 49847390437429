import { Router } from '@angular/router';
import { AuthService } from 'clickiren-api';
import { PUBLIC_HOME_PATH, AUTH_PATH } from '@shared/const/routing-path';
import { TtLogService } from 'tt-log';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "clickiren-api";
import * as i3 from "tt-log";
var AuthenticationGuard = /** @class */ (function () {
    function AuthenticationGuard(router, authSrv, log) {
        this.router = router;
        this.authSrv = authSrv;
        this.log = log;
    }
    AuthenticationGuard.prototype.canLoad = function (route) {
        try {
            if (this.authSrv.getUser()) {
                return true;
            }
            else {
                // TODO: redirect to home or special page?
                this.router.navigate([PUBLIC_HOME_PATH]);
            }
        }
        catch (err) {
            this.log.error('[AuthenticationGuard] error', err);
            this.router.navigate([AUTH_PATH]);
        }
    };
    AuthenticationGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationGuard_Factory() { return new AuthenticationGuard(i0.inject(i1.Router), i0.inject(i2.AuthService), i0.inject(i3.TtLogService)); }, token: AuthenticationGuard, providedIn: "root" });
    return AuthenticationGuard;
}());
export { AuthenticationGuard };
