import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var EventManagementService = /** @class */ (function () {
    function EventManagementService() {
        this.loginEvent = new Subject();
        this.associationFilterEvent = new Subject();
    }
    EventManagementService.prototype.sendLoginEvent = function (isLogin) {
        this.loginEvent.next(isLogin);
    };
    EventManagementService.prototype.onLoginEvent = function () {
        return this.loginEvent.asObservable();
    };
    EventManagementService.prototype.sendAssociationFilter = function (newfilter) {
        this.associationFilterEvent.next(newfilter);
    };
    EventManagementService.prototype.onAssociationFilter = function () {
        return this.associationFilterEvent.asObservable();
    };
    EventManagementService.ngInjectableDef = i0.defineInjectable({ factory: function EventManagementService_Factory() { return new EventManagementService(); }, token: EventManagementService, providedIn: "root" });
    return EventManagementService;
}());
export { EventManagementService };
