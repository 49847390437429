import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService } from 'clickiren-api';
import { PUBLIC_HOME_PATH, AUTH_PATH } from '@shared/const/routing-path';
import { TtLogService } from 'tt-log';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanLoad {

  constructor(
    private router: Router,
    private authSrv: AuthService,
    private log: TtLogService
  ) {

  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    try {
      if (this.authSrv.getUser()) {
        return true;
      } else {
        // TODO: redirect to home or special page?
        this.router.navigate([PUBLIC_HOME_PATH]);
      }
    } catch (err) {
      this.log.error('[AuthenticationGuard] error', err);
      this.router.navigate([AUTH_PATH]);
    }

  }
}
