/// LOGIN
export const LOGIN_API_PATH = '/api/v1/auth/login';

/// auth path
export const AUTH_PATH = 'auth';

/// public paths
export const PUBLIC_PATH = 'it';
/// public home
export const PUBLIC_HOME = 'home';
export const PUBLIC_HOME_PATH = `${PUBLIC_PATH}/${PUBLIC_HOME}`;

/// new contract
export const NEW_CONTRACT = 'offerte-luce-gas';
export const NEW_CONTRACT_PATH = `${PUBLIC_PATH}/${NEW_CONTRACT}`;

/// new contract - change supplier
export const CHANGE_SUPPLIER = 'cambio-fornitore';
export const CHANGE_SUPPLIER_PATH = `${NEW_CONTRACT_PATH}/${CHANGE_SUPPLIER}`;

/// new contract - contract transfer
export const CONTRACT_TRANSFER = 'voltura';
export const CONTRACT_TRANSFER_PATH = `${NEW_CONTRACT_PATH}/${CONTRACT_TRANSFER}`;

/// new contract - new activation or takeover
export const NEW_ACTIVATION = 'nuova-attivazione';
export const NEW_ACTIVATION_PATH = `${NEW_CONTRACT_PATH}/${NEW_ACTIVATION}`;

/// switchin
export const SWITCHIN = 'nuovo-contratto';
export const SWITCHIN_PATH = `${PUBLIC_PATH}/${SWITCHIN}`;

/// switchin info
export const SWITCHIN_INFO = ':sfid/info';
export const SWITCHIN_INFO_END_PATH = 'info';

/// switchin personal-data
export const SWITCHIN_PERSONAL_DATA = ':sfid/dati-personali';
export const SWITCHIN_PERSONAL_DATA_END_PATH = 'dati-personali';

/// switchin supply-info
export const SWITCHIN_SUPPLY_INFO = ':sfid/dati-fornitura';
export const SWITCHIN_SUPPLY_INFO_END_PATH = 'dati-fornitura';

/// switchin supply-info
export const SWITCHIN_PAYMENT_INFO = ':sfid/dati-pagamento';
export const SWITCHIN_PAYMENT_INFO_END_PATH = 'dati-pagamento';

/// switchin authorization
export const SWITCHIN_AUTHORIZATION = ':sfid/autorizzazione';
export const SWITCHIN_AUTHORIZATION_END_PATH = 'autorizzazione';

/// switchin recap
export const SWITCHIN_RECAP = ':sfid/riepilogo';
export const SWITCHIN_RECAP_END_PATH = 'riepilogo';

/// switchin generic
export const SWITCHIN_GENERIC_PATH = ':sfid/switchin/:stepname';
export const SWITCHIN_GENERIC_END_PATH = 'switchin/:stepname';

/// shops
export const SHOPS = 'trovami';
export const SHOPS_PATH = `${PUBLIC_PATH}/${SHOPS}`;

/// contacts
export const CONTACTS = 'contatti';
export const CONTACTS_PATH = `${PUBLIC_PATH}/${CONTACTS}`;

/// extra
export const EXTRA = 'extra';
export const EXTRA_PATH = `${PUBLIC_PATH}/${EXTRA}`;

/// extra power
export const EXTRA_POWER = 'consigli-luce';
export const EXTRA_POWER_PATH = `${EXTRA_PATH}/${EXTRA_POWER}`;

/// extra waste
export const EXTRA_WASTE = 'consigli-rifiuti';
export const EXTRA_WASTE_PATH = `${EXTRA_PATH}/${EXTRA_WASTE}`;

/// extra gas
export const EXTRA_GAS = 'consigli-gas';
export const EXTRA_GAS_PATH = `${EXTRA_PATH}/${EXTRA_GAS}`;

/// extra water
export const EXTRA_WATER = 'consigli-acqua';
export const EXTRA_WATER_PATH = `${EXTRA_PATH}/${EXTRA_WATER}`;

/// registration
export const REGISTRATION = 'registrazione';
export const REGISTRATION_PATH = `${PUBLIC_PATH}/${REGISTRATION}`;

/// solutions
export const SOLUTIONS = 'soluzioni-casa';
export const SOLUTIONS_PATH = `${PUBLIC_PATH}/${SOLUTIONS}`;

/// services
export const SERVICES = 'altri-servizi';
export const SERVICES_PATH = `${PUBLIC_PATH}/${SERVICES}`;

/// promo and events
export const EVENTS = 'promo-ed-eventi';
export const EVENTS_PATH = `${PUBLIC_PATH}/${EVENTS}`;

/// private paths
export const PRIVATE_PATH = 'my-iren';

/// private home
export const PRIVATE_HOME = 'home';
export const PRIVATE_HOME_PATH = `${PRIVATE_PATH}/${PRIVATE_HOME}`;

/// private contracts
export const PRIVATE_CONTRACTS = 'contratti';
export const PRIVATE_CONTRACTS_PATH = `${PRIVATE_PATH}/${PRIVATE_CONTRACTS}`;

/// private invoices
export const PRIVATE_INVOICES = 'fatture';
export const PRIVATE_INVOICES_PATH = `${PRIVATE_PATH}/${PRIVATE_INVOICES}`;

/// private profile
export const PRIVATE_PROFILE = 'profilo';
export const PRIVATE_PROFILE_PATH = `${PRIVATE_PATH}/${PRIVATE_PROFILE}`;

/// registration data
export const PRIVATE_REGISTRATION_DATA = 'dati-registrazione';
export const PRIVATE_REGISTRATION_DATA_PATH = `${PRIVATE_PROFILE_PATH}/${PRIVATE_REGISTRATION_DATA}`;

/// private requests
export const PRIVATE_REQUESTS = 'richieste';
export const PRIVATE_REQUESTS_PATH = `${PRIVATE_PATH}/${PRIVATE_REQUESTS}`;

/// private requests - info
export const PRIVATE_REQUEST_INFO = 'info';
export const PRIVATE_REQUEST_INFO_PATH = `${PRIVATE_REQUESTS_PATH}/${PRIVATE_REQUEST_INFO}`;

/// private requests - complaints
export const PRIVATE_REQUEST_COMPLAINTS = 'reclami';
export const PRIVATE_REQUEST_COMPLAINTS_PATH = `${PRIVATE_REQUESTS_PATH}/${PRIVATE_REQUEST_COMPLAINTS}`;

/// private associations
export const PRIVATE_ASSOCIATIONS = 'associazioni';
export const PRIVATE_ASSOCIATIONS_PATH = `${PRIVATE_PROFILE_PATH}/${PRIVATE_ASSOCIATIONS}`;
