import { Component, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

import { PUBLIC_HOME_PATH, NEW_CONTRACT_PATH, SHOPS_PATH, SOLUTIONS_PATH, SERVICES_PATH, EVENTS_PATH } from '@shared/const/routing-path';
import { BaseComponent } from '@shared/base-component';
import { MenuItem } from '@shared/menu-item';

@Component({
  selector: 'iren-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
  animations: [
    trigger('heightInOut', [
      state('in', style({ height: '*' })),
      transition(':enter', [style({ height: '0', opacity: '0' }), animate('180ms ease-out')]),
      transition(':leave', [style({ height: '*', opacity: '100' }), animate('200ms ease-in')])
    ])
  ]
})
export class HeaderMenuComponent extends BaseComponent implements OnInit {

  @Input() showMenu = false;
  @Input() privateMenu: MenuItem[] = [];

  @Output() isChangedRoute = new EventEmitter<boolean>();
  @Output() isLogOut = new EventEmitter<boolean>();

  menuItems: MenuItem[] = [];

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.translate.get([
      'PUBLIC.MENU.HOME',
      'PUBLIC.MENU.NEW_CONTRACTS',
      'PUBLIC.MENU.SHOPS',
      'PUBLIC.MENU.EXTRA',
      'PUBLIC.MENU.SOLUTIONS',
      'PUBLIC.MENU.SERVICES',
      'PUBLIC.MENU.EVENTS'
    ]).subscribe(
      res => {
        this.menuItems.push({ label: res['PUBLIC.MENU.HOME'], link: PUBLIC_HOME_PATH });
        this.menuItems.push({ label: res['PUBLIC.MENU.NEW_CONTRACTS'], link: NEW_CONTRACT_PATH });
        this.menuItems.push({ label: res['PUBLIC.MENU.SOLUTIONS'], link: SOLUTIONS_PATH });
        this.menuItems.push({ label: res['PUBLIC.MENU.SERVICES'], link: SERVICES_PATH });
        this.menuItems.push({ label: res['PUBLIC.MENU.EVENTS'], link: EVENTS_PATH });
        this.menuItems.push({ label: res['PUBLIC.MENU.SHOPS'], link: SHOPS_PATH });

      }
    );
  }

  onChangeRoute() {
    this.isChangedRoute.emit(false);
  }

  logout() {
    this.isLogOut.emit(true);
    this.isChangedRoute.emit(false);
  }

}
