/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./header-menu.component";
var styles_HeaderMenuComponent = [i0.styles];
var RenderType_HeaderMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderMenuComponent, data: { "animation": [{ type: 7, name: "heightInOut", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { height: "*" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { height: "0", opacity: "0" }, offset: null }, { type: 4, styles: null, timings: "180ms ease-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { height: "*", opacity: "100" }, offset: null }, { type: 4, styles: null, timings: "200ms ease-in" }], options: null }], options: {} }] } });
export { RenderType_HeaderMenuComponent as RenderType_HeaderMenuComponent };
function View_HeaderMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["routerLinkActive", "menu-active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onChangeRoute() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.link; _ck(_v, 1, 0, currVal_2); var currVal_3 = "menu-active"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.label; _ck(_v, 5, 0, currVal_4); }); }
function View_HeaderMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["routerLinkActive", "menu-active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onChangeRoute() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[4, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.link; _ck(_v, 1, 0, currVal_2); var currVal_3 = "menu-active"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.label; _ck(_v, 5, 0, currVal_4); }); }
function View_HeaderMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ul", [["class", "private-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderMenuComponent_4)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.privateMenu; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("PRIVATE.MENU.LOGOUT")); _ck(_v, 4, 0, currVal_1); }); }
function View_HeaderMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "menu-opened d-flex justify-content-center"]], [[24, "@heightInOut", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "public-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderMenuComponent_2)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderMenuComponent_3)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.menuItems; _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.privateMenu && (_co.privateMenu.length > 0)); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = "in"; _ck(_v, 0, 0, currVal_0); }); }
export function View_HeaderMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderMenuComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showMenu; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HeaderMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "iren-header-menu", [], null, null, null, View_HeaderMenuComponent_0, RenderType_HeaderMenuComponent)), i1.ɵdid(1, 114688, null, 0, i5.HeaderMenuComponent, [i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderMenuComponentNgFactory = i1.ɵccf("iren-header-menu", i5.HeaderMenuComponent, View_HeaderMenuComponent_Host_0, { showMenu: "showMenu", privateMenu: "privateMenu" }, { isChangedRoute: "isChangedRoute", isLogOut: "isLogOut" }, []);
export { HeaderMenuComponentNgFactory as HeaderMenuComponentNgFactory };
