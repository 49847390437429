import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FormGeneratorModule } from 'form-generator';

import { TranslateModule } from '@ngx-translate/core';

// primeng
import { InputTextModule } from 'primeng/inputtext';
import { CaptchaModule } from 'primeng/captcha';
import { GMapModule } from 'primeng/gmap';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { MessagesModule } from 'primeng/messages';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';

import { LoaderComponent } from '@shared/loader/loader.component';
import { BannerMessageComponent } from '@shared/banner-message/banner-message.component';
import { InvoiceRowComponent } from '@shared/invoice-row/invoice-row.component';
import { InvoiceTableComponent } from '@shared/invoice-table/invoice-table.component';
import { ContractTableComponent } from '@shared/contract-table/contract-table.component';
import { ContractRowComponent } from '@shared/contract-row/contract-row.component';
import { PageWrapperComponent } from '@shared/page-wrapper/page-wrapper.component';
import { WidgetWrapperComponent } from '@shared/widget-wrapper/widget-wrapper.component';
import { TableFormWidgetComponent } from '@shared/table-form-widget/table-form-widget.component';
import { AssociationTableComponent } from '@shared/associations/association-table/association-table.component';
import { AssociationFormComponent } from '@shared/associations/association-form/association-form.component';
import { CasesTableComponent } from './cases-table/cases-table.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild(),
    BlockUIModule,
    MessagesModule,
    ToastModule,
    ButtonModule
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    FormGeneratorModule,
    // iren components
    LoaderComponent,
    BannerMessageComponent,
    InvoiceTableComponent,
    ContractTableComponent,
    PageWrapperComponent,
    WidgetWrapperComponent,
    TableFormWidgetComponent,
    AssociationTableComponent,
    AssociationFormComponent,
    CasesTableComponent,
    // primeng
    InputTextModule,
    CaptchaModule,
    GMapModule,
    AccordionModule,
    AutoCompleteModule,
    CheckboxModule,
    DialogModule,
    MessagesModule,
    DropdownModule,
    ConfirmDialogModule,
    CalendarModule,
    ToastModule
  ],
  declarations: [
    LoaderComponent,
    BannerMessageComponent,
    InvoiceRowComponent,
    InvoiceTableComponent,
    ContractTableComponent,
    ContractRowComponent,
    PageWrapperComponent,
    WidgetWrapperComponent,
    TableFormWidgetComponent,
    AssociationTableComponent,
    AssociationFormComponent,
    CasesTableComponent
  ]
})
export class SharedModule { }
