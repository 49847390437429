import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'clickiren-api';

import {
  PRIVATE_HOME_PATH,
  REGISTRATION_PATH,
  PRIVATE_CONTRACTS_PATH,
  PRIVATE_INVOICES_PATH,
  PRIVATE_PROFILE_PATH,
  PUBLIC_HOME_PATH,
  AUTH_PATH,
  PRIVATE_REQUESTS_PATH,
  LOGIN_API_PATH
} from '@shared/const/routing-path';
import { BaseComponent } from '@shared/base-component';
import { MenuItem } from '@shared/menu-item';
import { EventManagementService } from '@shared/services/event-management.service';


@Component({
  selector: 'iren-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {


  showMenu = false;
  showLogin = true;

  privateMenu: MenuItem[] = [];

  constructor(
    private eventMngSrv: EventManagementService,
    private authSrv: AuthService,
    private router: Router,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.translate.get(
      [
        'PRIVATE.MENU.HOME',
        'PRIVATE.MENU.CONTRACTS',
        'PRIVATE.MENU.INVOICES',
        'PRIVATE.MENU.REQUESTS',
        'PRIVATE.MENU.USER_PROFILE'
      ]
    ).subscribe(
      res => {
        this.privateMenu.push({ label: res['PRIVATE.MENU.HOME'], link: PRIVATE_HOME_PATH });
        this.privateMenu.push({ label: res['PRIVATE.MENU.CONTRACTS'], link: PRIVATE_CONTRACTS_PATH });
        this.privateMenu.push({ label: res['PRIVATE.MENU.INVOICES'], link: PRIVATE_INVOICES_PATH });
        this.privateMenu.push({ label: res['PRIVATE.MENU.REQUESTS'], link: PRIVATE_REQUESTS_PATH });
        this.privateMenu.push({ label: res['PRIVATE.MENU.USER_PROFILE'], link: PRIVATE_PROFILE_PATH });
      }
    );
    try {
      const u = this.authSrv.getUser();
      if (u) {
        this.showLogin = false;
      } else {
        this.showLogin = true;
      }
    } catch {
      this.showLogin = true;
    }

    this.subscription = this.eventMngSrv.onLoginEvent().subscribe(
      res => {
        this.showLogin = !res;
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
    this.go2Top();
  }

  goToHome() {
    this.router.navigate([PUBLIC_HOME_PATH]);
  }

  goToLogin() {
    window.open(LOGIN_API_PATH, '_blank', 'scrollbars=no,resizable=yes, width=500,height=600,status=no,location=no,toolbar=no');
  }

  goToRegistration() {
    this.router.navigate([REGISTRATION_PATH]);
  }

  logout() {
    this.messageHandler.showConfirmation(
      'Attenzione',
      'Gentile Cliente, sei sicuro di volere effettuare il logout?',
      'pi pi-exclamation-triangle',
      'Si',
      'No',
      () => {
        this.authSrv.removeUser();
        this.eventMngSrv.sendLoginEvent(false);
        this.router.navigate([AUTH_PATH]);
      });
  }

  onChangeRoute() {

  }

}
