var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector, EventEmitter } from '@angular/core';
import { PUBLIC_HOME_PATH, NEW_CONTRACT_PATH, SHOPS_PATH, SOLUTIONS_PATH, SERVICES_PATH, EVENTS_PATH } from '@shared/const/routing-path';
import { BaseComponent } from '@shared/base-component';
var HeaderMenuComponent = /** @class */ (function (_super) {
    __extends(HeaderMenuComponent, _super);
    function HeaderMenuComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.showMenu = false;
        _this.privateMenu = [];
        _this.isChangedRoute = new EventEmitter();
        _this.isLogOut = new EventEmitter();
        _this.menuItems = [];
        return _this;
    }
    HeaderMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translate.get([
            'PUBLIC.MENU.HOME',
            'PUBLIC.MENU.NEW_CONTRACTS',
            'PUBLIC.MENU.SHOPS',
            'PUBLIC.MENU.EXTRA',
            'PUBLIC.MENU.SOLUTIONS',
            'PUBLIC.MENU.SERVICES',
            'PUBLIC.MENU.EVENTS'
        ]).subscribe(function (res) {
            _this.menuItems.push({ label: res['PUBLIC.MENU.HOME'], link: PUBLIC_HOME_PATH });
            _this.menuItems.push({ label: res['PUBLIC.MENU.NEW_CONTRACTS'], link: NEW_CONTRACT_PATH });
            _this.menuItems.push({ label: res['PUBLIC.MENU.SOLUTIONS'], link: SOLUTIONS_PATH });
            _this.menuItems.push({ label: res['PUBLIC.MENU.SERVICES'], link: SERVICES_PATH });
            _this.menuItems.push({ label: res['PUBLIC.MENU.EVENTS'], link: EVENTS_PATH });
            _this.menuItems.push({ label: res['PUBLIC.MENU.SHOPS'], link: SHOPS_PATH });
        });
    };
    HeaderMenuComponent.prototype.onChangeRoute = function () {
        this.isChangedRoute.emit(false);
    };
    HeaderMenuComponent.prototype.logout = function () {
        this.isLogOut.emit(true);
        this.isChangedRoute.emit(false);
    };
    return HeaderMenuComponent;
}(BaseComponent));
export { HeaderMenuComponent };
