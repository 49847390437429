import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventManagementService {

  private loginEvent = new Subject<boolean>();

  private associationFilterEvent = new Subject<string>();

  constructor() { }

  public sendLoginEvent(isLogin: boolean) {
    this.loginEvent.next(isLogin);
  }

  public onLoginEvent(): Observable<boolean> {
    return this.loginEvent.asObservable();
  }

  public sendAssociationFilter(newfilter: string) {
    this.associationFilterEvent.next(newfilter);
  }

  public onAssociationFilter(): Observable<string> {
    return this.associationFilterEvent.asObservable();
  }
}
