import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ConfirmationService } from 'primeng/api';

export interface IrenMessage {
  summary?: string;
  severity: 'success' | 'error' | 'info' | 'warn';
  message: string;
  key?: string;

}
@Injectable({
  providedIn: 'root'
})
export class MessageHandlerService {

  private messageSubject = new Subject<IrenMessage>();

  constructor(
    private confirmationSrv: ConfirmationService
  ) { }

  public sendSuccessMessage(message: string, summary?: string, key?: string) {
    this.sendMessage(message, 'success', summary, key);
  }

  public sendInfoMessage(message: string, summary?: string, key?: string) {
    this.sendMessage(message, 'info', summary, key);
  }

  public sendWarnMessage(message: string, summary?: string, key?: string) {
    this.sendMessage(message, 'warn', summary, key);
  }

  public sendErrorMessage(message: string, summary?: string, key?: string) {
    this.sendMessage(message, 'error', summary, key);
  }

  public sendMessage(message: string, severity: 'success' | 'error' | 'info' | 'warn', summary?: string, key?: string) {
    const msg: IrenMessage = {
      severity: severity,
      message: message,
      summary: summary,
      key
    };
    this.messageSubject.next(msg);
  }

  public handleMessage(): Observable<IrenMessage> {
    return this.messageSubject.asObservable();
  }

  public showConfirmation(
    header: string,
    message: string,
    icon: string,
    acceptLabel: string,
    rejectLabel: string,
    acceptCallback: any,
    rejectCallback?: any
  ) {
    this.confirmationSrv.confirm({
      header: header,
      message: message,
      icon: icon,
      acceptLabel: acceptLabel,
      rejectLabel: rejectLabel,
      accept: () => acceptCallback(),
      reject: () => {
        if (rejectCallback) {
          rejectCallback();
        }
      }
    });
  }
}
