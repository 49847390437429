import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TtErrorHandler } from 'tt-base-handler';
registerLocaleData(localeIt, 'it');
export function errorHandlerFactory(injector) {
    return new TtErrorHandler(injector);
}
export function createTranslateLoader(http) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
var ɵ0 = (createTranslateLoader);
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
