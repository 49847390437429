<div class="cookie-container bg-secondary pb-3 w-100" *ngIf="showCookiePolicy">
  <div class="cookie d-flex justify-content-center pt-3">

    <p class="text-center" [innerHTML]="'PUBLIC.FOOTER.COOKIE' | translate"></p>

  </div>
  <div class="cookie d-flex justify-content-center w-100 pt-1">

    <button type="button" class="btn btn-light cookie-button" (click)="acceptCookie()">{{'PUBLIC.FOOTER.BTN' | translate}}</button>

  </div>
</div>

<footer class="bg-dark w-100 pt-1 pb-1 m-0 d-flex align-items-center justify-content-center" [class.footer-home]="isHome">

  <p class="text-light text-center m-0">{{'PUBLIC.FOOTER.MSG' | translate}}</p>

</footer>
