var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector } from '@angular/core';
import { BaseComponent } from '@shared/base-component';
import { CookieService } from 'ngx-cookie';
export var COOKIE_NAME = 'cookie_accepted';
export var COOKIE_VALUE = 'true';
var FooterComponent = /** @class */ (function (_super) {
    __extends(FooterComponent, _super);
    function FooterComponent(cookieSrv, injector) {
        var _this = _super.call(this, injector) || this;
        _this.cookieSrv = cookieSrv;
        _this.isHome = false;
        _this.showCookiePolicy = true;
        return _this;
    }
    FooterComponent.prototype.ngOnInit = function () {
        var cookie = this.cookieSrv.get(COOKIE_NAME);
        this.showCookiePolicy = !(COOKIE_VALUE === cookie);
    };
    FooterComponent.prototype.acceptCookie = function () {
        this.cookieSrv.put(COOKIE_NAME, COOKIE_VALUE);
        this.showCookiePolicy = false;
    };
    return FooterComponent;
}(BaseComponent));
export { FooterComponent };
