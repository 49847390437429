import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';

import { PUBLIC_PATH, PRIVATE_PATH, AUTH_PATH } from '@shared/const/routing-path';

import { AuthComponent } from './auth/auth.component';

import { AuthenticationGuard } from 'src/app/_guard/authentication.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: AUTH_PATH,
    pathMatch: 'full'
  },
  {
    path: AUTH_PATH,
    component: AuthComponent
  },
  {
    path: PUBLIC_PATH,
    loadChildren: 'src/app/public/public.module#PublicModule'
  },
  {
    path: PRIVATE_PATH,
    loadChildren: 'src/app/private/private.module#PrivateModule',
    canLoad: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(private router: Router) {
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationEnd) {
          console.log('set ga pageview', event.urlAfterRedirects);
        }
      }
    );
  }
}
