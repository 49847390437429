var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'clickiren-api';
import { PRIVATE_HOME_PATH, REGISTRATION_PATH, PRIVATE_CONTRACTS_PATH, PRIVATE_INVOICES_PATH, PRIVATE_PROFILE_PATH, PUBLIC_HOME_PATH, AUTH_PATH, PRIVATE_REQUESTS_PATH, LOGIN_API_PATH } from '@shared/const/routing-path';
import { BaseComponent } from '@shared/base-component';
import { EventManagementService } from '@shared/services/event-management.service';
var HeaderComponent = /** @class */ (function (_super) {
    __extends(HeaderComponent, _super);
    function HeaderComponent(eventMngSrv, authSrv, router, injector) {
        var _this = _super.call(this, injector) || this;
        _this.eventMngSrv = eventMngSrv;
        _this.authSrv = authSrv;
        _this.router = router;
        _this.showMenu = false;
        _this.showLogin = true;
        _this.privateMenu = [];
        return _this;
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translate.get([
            'PRIVATE.MENU.HOME',
            'PRIVATE.MENU.CONTRACTS',
            'PRIVATE.MENU.INVOICES',
            'PRIVATE.MENU.REQUESTS',
            'PRIVATE.MENU.USER_PROFILE'
        ]).subscribe(function (res) {
            _this.privateMenu.push({ label: res['PRIVATE.MENU.HOME'], link: PRIVATE_HOME_PATH });
            _this.privateMenu.push({ label: res['PRIVATE.MENU.CONTRACTS'], link: PRIVATE_CONTRACTS_PATH });
            _this.privateMenu.push({ label: res['PRIVATE.MENU.INVOICES'], link: PRIVATE_INVOICES_PATH });
            _this.privateMenu.push({ label: res['PRIVATE.MENU.REQUESTS'], link: PRIVATE_REQUESTS_PATH });
            _this.privateMenu.push({ label: res['PRIVATE.MENU.USER_PROFILE'], link: PRIVATE_PROFILE_PATH });
        });
        try {
            var u = this.authSrv.getUser();
            if (u) {
                this.showLogin = false;
            }
            else {
                this.showLogin = true;
            }
        }
        catch (_a) {
            this.showLogin = true;
        }
        this.subscription = this.eventMngSrv.onLoginEvent().subscribe(function (res) {
            _this.showLogin = !res;
        });
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe();
    };
    HeaderComponent.prototype.toggleMenu = function () {
        this.showMenu = !this.showMenu;
        this.go2Top();
    };
    HeaderComponent.prototype.goToHome = function () {
        this.router.navigate([PUBLIC_HOME_PATH]);
    };
    HeaderComponent.prototype.goToLogin = function () {
        window.open(LOGIN_API_PATH, '_blank', 'scrollbars=no,resizable=yes, width=500,height=600,status=no,location=no,toolbar=no');
    };
    HeaderComponent.prototype.goToRegistration = function () {
        this.router.navigate([REGISTRATION_PATH]);
    };
    HeaderComponent.prototype.logout = function () {
        var _this = this;
        this.messageHandler.showConfirmation('Attenzione', 'Gentile Cliente, sei sicuro di volere effettuare il logout?', 'pi pi-exclamation-triangle', 'Si', 'No', function () {
            _this.authSrv.removeUser();
            _this.eventMngSrv.sendLoginEvent(false);
            _this.router.navigate([AUTH_PATH]);
        });
    };
    HeaderComponent.prototype.onChangeRoute = function () {
    };
    return HeaderComponent;
}(BaseComponent));
export { HeaderComponent };
