import { Injector } from '@angular/core';

import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { ConfirmationService } from 'primeng/api';

import { TtLogService } from 'tt-log';
import { BaseNetworkErrorService } from 'tt-base-handler';
import { ResponseWrapper, PageParams, Commodity } from 'clickiren-api';

import { MessageHandlerService } from '@shared/services/message-handler.service';
import { getBackgroundColor, getTextColor, COMMODITY_LABEL_MAP, getIcon } from '@shared/const/commodity-utils';
import { TtmyValidatorService } from 'form-generator';


export class BaseComponent {
  public showLoader = false;

  public defaultRows = 10;

  public it = {
    firstDayOfWeek: 1,
    dayNames: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
    dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
    // tslint:disable-next-line:max-line-length
    monthNames: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre'
    ],
    monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
    today: 'Oggi',
    clear: 'Annulla'
  };

  protected subscription: Subscription;

  protected log: TtLogService;
  protected translate: TranslateService;
  protected errorHandler: BaseNetworkErrorService;
  protected scrollToSrv: ScrollToService;
  protected messageHandler: MessageHandlerService;
  protected confirmationSrv: ConfirmationService;
  protected validationSrv: TtmyValidatorService;

  constructor(injector: Injector) {
    this.log = injector.get(TtLogService);
    this.translate = injector.get(TranslateService);
    this.errorHandler = injector.get(BaseNetworkErrorService);
    this.scrollToSrv = injector.get(ScrollToService);
    this.messageHandler = injector.get(MessageHandlerService);
    this.validationSrv = injector.get(TtmyValidatorService);
  }

  public handleResponseWrapper<T>(r: ResponseWrapper<T>, callbackFailure?: any) {
    this.showLoader = false;
    if (r && r.status === 'OK') {
      return r.data;
    }
    if (callbackFailure) {
      callbackFailure();
    }
  }

  protected unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  protected go2Top() {
    this.scrollToSrv.scrollTo({
      target: 'top'
    });
  }

  protected getPageParams(
    defaultSortField: string,
    defaultSortOrder: 'DESC' | 'ASC' = 'DESC',
    page: number = 1,
    perPage: number = 0
  ) {
    const params: PageParams = {
      page: page,
      perPage: perPage,
      sortField: defaultSortField,
      sortOrder: defaultSortOrder
    };

    return params;
  }

  getCommodityIcon(commodity: Commodity) {
    return getIcon(commodity);
  }

  getCommodityBackground(commodity: Commodity, light?: boolean) {
    return getBackgroundColor(commodity, light);
  }

  getCommodityTextColor(commodity: Commodity) {
    return getTextColor(commodity);
  }

  getCommodityLabel(commodity) {
    return COMMODITY_LABEL_MAP.get(commodity);
  }

  // protected getFilters(columns: string[]) {
  //   const filters: Filter[] = [];
  //   if (event && event.filters) {
  //     columns.forEach(c => {
  //       if (event.filters[c]) {
  //         filters.push({ key: c, value: event.filters[c].value });
  //       }
  //     });
  //   }
  //   return filters;
  // }
}
