/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header/header.component.ngfactory";
import * as i3 from "./header/header.component";
import * as i4 from "./shared/services/event-management.service";
import * as i5 from "clickiren-api";
import * as i6 from "@angular/router";
import * as i7 from "./footer/footer.component.ngfactory";
import * as i8 from "./footer/footer.component";
import * as i9 from "ngx-cookie";
import * as i10 from "../../node_modules/primeng/components/confirmdialog/confirmdialog.ngfactory";
import * as i11 from "primeng/components/dom/domhandler";
import * as i12 from "primeng/components/confirmdialog/confirmdialog";
import * as i13 from "primeng/components/common/confirmationservice";
import * as i14 from "./app.component";
import * as i15 from "@angular/platform-browser";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "a", [["id", "top"], ["name", "top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "iren-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 245760, null, 0, i3.HeaderComponent, [i4.EventManagementService, i5.AuthService, i6.Router, i1.Injector], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "container-fluid main-container p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "iren-footer", [], null, null, null, i7.View_FooterComponent_0, i7.RenderType_FooterComponent)), i1.ɵdid(7, 114688, null, 0, i8.FooterComponent, [i9.CookieService, i1.Injector], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "p-confirmDialog", [["width", "400"]], null, null, null, i10.View_ConfirmDialog_0, i10.RenderType_ConfirmDialog)), i1.ɵprd(512, null, i11.DomHandler, i11.DomHandler, []), i1.ɵdid(10, 180224, null, 1, i12.ConfirmDialog, [i1.ElementRef, i11.DomHandler, i1.Renderer2, i13.ConfirmationService, i1.NgZone], { width: [0, "width"] }, null), i1.ɵqud(335544320, 1, { footer: 0 })], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); var currVal_0 = "400"; _ck(_v, 10, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "iren-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i14.AppComponent, [i6.Router, i4.EventManagementService, i5.AuthService, i15.Meta, i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("iren-root", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
