<header class="bg-light w-100 sticky-top">
  <div class="logo float-left" (click)="goToHome()">
    <img src="/assets/images/logo_iren.png" alt="clickiren">
  </div>


  <div class="float-right d-flex align-items-center header-buttons">

    <button *ngIf="showLogin" type="button" class="btn btn-light float-left sign-in" (click)="goToRegistration()">
      <span class="icon-signin"></span>
      <span class="registration-text">{{'PUBLIC.MENU.REGISTATION' | translate}}</span>
    </button>

    <button *ngIf="showLogin" type="button" class="btn btn-light float-left log-in" (click)="goToLogin()">
      <span class="icon-locker"></span>
      <span class="registration-text">{{'PUBLIC.MENU.LOGIN' | translate}}</span>
    </button>
    <div *ngIf="!showLogin" class="private-menu">
      <div class="btn-group private-menu-smartphone">
        <!-- <button type="button" class="btn btn-light dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          My Iren
        </button> -->
        <div class="dropdown-menu dropdown-menu-right">
          <ng-container *ngFor="let m of privateMenu">
            <button class="dropdown-item pt-2 pb-2" type="button" [routerLink]="m.link">{{m.label}}</button>
            <div class="dropdown-divider"></div>
          </ng-container>
          <button class="dropdown-item pt-2 pb-2" type="button" (click)="logout()">{{'PRIVATE.MENU.LOGOUT' | translate}}</button>
        </div>
      </div>
      <ul class="d-flex align-items-center">
        <li *ngFor="let m of privateMenu">
          <a [routerLink]="m.link" routerLinkActive="private-menu-active" (click)="onChangeRoute()">{{m.label}}</a>
        </li>
        <li>
          <a class="d-flex align-items-center hover-none cursor-pointer" (click)="logout()">
            <span class="icon-logout mr-2"></span>{{'PRIVATE.MENU.LOGOUT' | translate}}</a>
        </li>
      </ul>
    </div>
    <button type="button" class="btn btn-light icon-hamburger-menu float-left" (click)="toggleMenu()"></button>
  </div>
</header>
<iren-header-menu [showMenu]="showMenu" [privateMenu]="privateMenu" (isChangedRoute)="showMenu=false;" (isLogOut)="logout()"></iren-header-menu>
