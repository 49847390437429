var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@shared/base-component';
import { PUBLIC_HOME_PATH } from '@shared/const/routing-path';
import { AuthService } from 'clickiren-api';
var AuthComponent = /** @class */ (function (_super) {
    __extends(AuthComponent, _super);
    function AuthComponent(authSrv, route, router, injector) {
        var _this = _super.call(this, injector) || this;
        _this.authSrv = authSrv;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    AuthComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.log.trace('init auth');
        this.route.queryParams.subscribe(function (p) {
            setTimeout(function () {
                _this.handleJwt(p);
            }, 500);
        });
    };
    AuthComponent.prototype.handleJwt = function (p) {
        this.jwt = p['jwt'];
        if (this.jwt) {
            this.log.trace('token jwt', this.jwt);
            var u = this.authSrv.setToken(this.jwt);
            this.log.trace('auth-user', u);
            if (u) {
                window.close();
            }
        }
        else {
            this.handlePublic();
        }
    };
    AuthComponent.prototype.handlePublic = function () {
        this.router.navigate([PUBLIC_HOME_PATH]);
    };
    return AuthComponent;
}(BaseComponent));
export { AuthComponent };
