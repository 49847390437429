var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { AuthService } from 'clickiren-api';
import { VERSION } from '../environments/version';
import { PRIVATE_HOME_PATH } from '@shared/const/routing-path';
import { EventManagementService } from '@shared/services/event-management.service';
import { BaseComponent } from '@shared/base-component';
var AppComponent = /** @class */ (function (_super) {
    __extends(AppComponent, _super);
    function AppComponent(router, eventMngSrv, authSrv, meta, injector) {
        var _this = _super.call(this, injector) || this;
        _this.router = router;
        _this.eventMngSrv = eventMngSrv;
        _this.authSrv = authSrv;
        _this.routeToPrivate = false;
        meta.addTag({ name: 'version', content: VERSION.version + " build: " + VERSION.hash });
        return _this;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.log.trace('init app');
        this.translate.setDefaultLang('it');
        this.translate.use('it');
        window.addEventListener('storage', function (listener) {
            _this.log.trace('storage listener', listener);
            var key = listener.key;
            var newValue = listener.newValue;
            if (key === AuthService.TOKEN_NAME && newValue) {
                _this.log.trace('storage new value', newValue);
                try {
                    if (_this.authSrv.getUser() && !_this.routeToPrivate) {
                        _this.routeToPrivate = true;
                        _this.eventMngSrv.sendLoginEvent(true);
                        _this.router.navigate([PRIVATE_HOME_PATH]);
                    }
                }
                catch (err) {
                    _this.log.error('getUser() error', err);
                }
            }
        });
    };
    return AppComponent;
}(BaseComponent));
export { AppComponent };
