import { Component, OnInit, Injector, Input } from '@angular/core';

import { BaseComponent } from '@shared/base-component';
import { CookieService } from 'ngx-cookie';

export const COOKIE_NAME = 'cookie_accepted';
export const COOKIE_VALUE = 'true';
@Component({
  selector: 'iren-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit {

  @Input() isHome = false;

  showCookiePolicy = true;

  constructor(
    private cookieSrv: CookieService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    const cookie = this.cookieSrv.get(COOKIE_NAME);
    this.showCookiePolicy = !(COOKIE_VALUE === cookie);
  }

  acceptCookie() {
    this.cookieSrv.put(COOKIE_NAME, COOKIE_VALUE);
    this.showCookiePolicy = false;
  }

}
