import { Subject } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/common/confirmationservice";
var MessageHandlerService = /** @class */ (function () {
    function MessageHandlerService(confirmationSrv) {
        this.confirmationSrv = confirmationSrv;
        this.messageSubject = new Subject();
    }
    MessageHandlerService.prototype.sendSuccessMessage = function (message, summary, key) {
        this.sendMessage(message, 'success', summary, key);
    };
    MessageHandlerService.prototype.sendInfoMessage = function (message, summary, key) {
        this.sendMessage(message, 'info', summary, key);
    };
    MessageHandlerService.prototype.sendWarnMessage = function (message, summary, key) {
        this.sendMessage(message, 'warn', summary, key);
    };
    MessageHandlerService.prototype.sendErrorMessage = function (message, summary, key) {
        this.sendMessage(message, 'error', summary, key);
    };
    MessageHandlerService.prototype.sendMessage = function (message, severity, summary, key) {
        var msg = {
            severity: severity,
            message: message,
            summary: summary,
            key: key
        };
        this.messageSubject.next(msg);
    };
    MessageHandlerService.prototype.handleMessage = function () {
        return this.messageSubject.asObservable();
    };
    MessageHandlerService.prototype.showConfirmation = function (header, message, icon, acceptLabel, rejectLabel, acceptCallback, rejectCallback) {
        this.confirmationSrv.confirm({
            header: header,
            message: message,
            icon: icon,
            acceptLabel: acceptLabel,
            rejectLabel: rejectLabel,
            accept: function () { return acceptCallback(); },
            reject: function () {
                if (rejectCallback) {
                    rejectCallback();
                }
            }
        });
    };
    MessageHandlerService.ngInjectableDef = i0.defineInjectable({ factory: function MessageHandlerService_Factory() { return new MessageHandlerService(i0.inject(i1.ConfirmationService)); }, token: MessageHandlerService, providedIn: "root" });
    return MessageHandlerService;
}());
export { MessageHandlerService };
