import { Commodity } from 'clickiren-api';

export const POWER_ICON = 'icon-luce-icon';
export const GAS_ICON = 'icon-gas-icon';
export const WASTE_ICON = 'icon-rifiuti-icon';
export const WATER_ICON = 'icon-acqua-icon';
export const DISTRICT_HEATING_ICON = 'icon-teleriscaldamento';

export const POWER_BG = 'bg-power';
export const GAS_BG = 'bg-gas';
export const WASTE_BG = 'bg-waste';
export const WATER_BG = 'bg-water';
export const DISTRICT_HEATING_BG = 'bg-district-heating';

export const POWER_BG_LIGHT = 'bg-power-50';
export const GAS_BG_LIGHT = 'bg-gas-50';
export const WASTE_BG_LIGHT = 'bg-waste-50';
export const WATER_BG_LIGHT = 'bg-water-50';
export const DISTRICT_HEATING_BG_LIGHT = 'bg-district-heating-50';

export const POWER_TEXT = 'text-power';
export const GAS_TEXT = 'text-gas';
export const WASTE_TEXT = 'text-waste';
export const WATER_TEXT = 'text-water';
export const DISTRICT_HEATING_TEXT = 'text-district-heating';

export const COMMODITY_LIST = ['power', 'gas', 'waste', 'water', 'district-heating'];
// tslint:disable-next-line:max-line-length
export const COMMODITY_KEYS = ['COMMON.COMMODITY.POWER', 'COMMON.COMMODITY.GAS', 'COMMON.COMMODITY.WASTE', 'COMMON.COMMODITY.WATER', 'COMMON.COMMODITY.DISTRICT_HEATING'];
export const COMMODITY_MAP = new Map([
  ['COMMON.COMMODITY.POWER', Commodity.POWER],
  ['COMMON.COMMODITY.GAS', Commodity.GAS],
  ['COMMON.COMMODITY.WASTE', Commodity.WASTE],
  ['COMMON.COMMODITY.WATER', Commodity.WATER],
  ['COMMON.COMMODITY.DISTRICT_HEATING', Commodity.DISTRICT_HEATING]
]);

export const COMMODITY_LABEL_MAP = new Map([
  [Commodity.POWER, 'COMMON.COMMODITY.POWER'],
  [Commodity.GAS, 'COMMON.COMMODITY.GAS'],
  [Commodity.WASTE, 'COMMON.COMMODITY.WASTE'],
  [Commodity.WATER, 'COMMON.COMMODITY.WATER'],
  [Commodity.DISTRICT_HEATING, 'COMMON.COMMODITY.DISTRICT_HEATING']
]);

export function getIcon(commodity: Commodity | 'power' | 'gas' | 'waste' | 'water' | 'district-heating') {
  switch (commodity) {
    case Commodity.POWER:
      return POWER_ICON;
    case Commodity.GAS:
      return GAS_ICON;
    case Commodity.WASTE:
      return WASTE_ICON;
    case Commodity.WATER:
      return WATER_ICON;
    case Commodity.DISTRICT_HEATING:
      return DISTRICT_HEATING_ICON;
    default:
      return null;
  }
}

export function getBackgroundColor(commodity: Commodity | 'power' | 'gas' | 'waste' | 'water' | 'district-heating', light?: boolean) {
  switch (commodity) {
    case Commodity.POWER:
      return light ? POWER_BG_LIGHT : POWER_BG;
    case Commodity.GAS:
      return light ? GAS_BG_LIGHT : GAS_BG;
    case Commodity.WASTE:
      return light ? WASTE_BG_LIGHT : WASTE_BG;
    case Commodity.WATER:
      return light ? WATER_BG_LIGHT : WASTE_BG;
    case Commodity.DISTRICT_HEATING:
      return light ? DISTRICT_HEATING_BG_LIGHT : DISTRICT_HEATING_BG;
    default:
      return null;
  }
}

export function getTextColor(commodity: Commodity | 'power' | 'gas' | 'waste' | 'water' | 'district-heating') {
  switch (commodity) {
    case Commodity.POWER:
      return POWER_TEXT;
    case Commodity.GAS:
      return GAS_TEXT;
    case Commodity.WASTE:
      return WASTE_TEXT;
    case Commodity.WATER:
      return WATER_TEXT;
    case Commodity.DISTRICT_HEATING:
      return DISTRICT_HEATING_TEXT;
    default:
      return null;
  }
}
