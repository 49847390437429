import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injector, ErrorHandler } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieModule } from 'ngx-cookie';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { MessageService } from 'primeng/components/common/messageservice';
import { ConfirmationService } from 'primeng/api';

import { TtBaseHandlerModule, TtErrorHandler } from 'tt-base-handler';
import { TtLogModule } from 'tt-log';
import { ClickirenApiModule } from 'clickiren-api';

import { APP_MOCK_CONFIG } from './app-mock-config';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { HeaderComponent } from './header/header.component';
import { HeaderMenuComponent } from './header/header-menu/header-menu.component';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from '@shared/shared.module';


registerLocaleData(localeIt, 'it');

export function errorHandlerFactory(injector: Injector) {
  return new TtErrorHandler(injector);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    HeaderComponent,
    HeaderMenuComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    CookieModule.forRoot(),
    ScrollToModule.forRoot(),
    TtLogModule.forRoot({
      level: environment.logLevel
    }),
    TtBaseHandlerModule.forRoot(),
    ClickirenApiModule.forRoot({
      mockConfig: APP_MOCK_CONFIG,
      cacheConfig: {
        useCache: environment.useCache
      }
    }),
    AppRoutingModule,
    SharedModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
      deps: [Injector]
    },
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
