import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';

import { AuthService } from 'clickiren-api';

import { VERSION } from '../environments/version';
import { PRIVATE_HOME_PATH } from '@shared/const/routing-path';
import { EventManagementService } from '@shared/services/event-management.service';
import { BaseComponent } from '@shared/base-component';



@Component({
  selector: 'iren-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {
  private routeToPrivate = false;
  constructor(
    private router: Router,
    private eventMngSrv: EventManagementService,
    private authSrv: AuthService,
    meta: Meta,
    injector: Injector
  ) {
    super(injector);
    meta.addTag({ name: 'version', content: `${VERSION.version} build: ${VERSION.hash}` });
  }

  ngOnInit(): void {
    this.log.trace('init app');

    this.translate.setDefaultLang('it');
    this.translate.use('it');

    window.addEventListener('storage', listener => {
      this.log.trace('storage listener', listener);
      const key = listener.key;
      const newValue = listener.newValue;
      if (key === AuthService.TOKEN_NAME && newValue) {
        this.log.trace('storage new value', newValue);
        try {
          if (this.authSrv.getUser() && !this.routeToPrivate) {
            this.routeToPrivate = true;
            this.eventMngSrv.sendLoginEvent(true);
            this.router.navigate([PRIVATE_HOME_PATH]);
          }
        } catch (err) {
          this.log.error('getUser() error', err);
        }
      }
    });
  }
}
