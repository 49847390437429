import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BaseComponent } from '@shared/base-component';
import { PUBLIC_HOME_PATH } from '@shared/const/routing-path';
import { AuthService } from 'clickiren-api';

@Component({
  selector: 'iren-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent extends BaseComponent implements OnInit {
  jwt: string;

  constructor(private authSrv: AuthService, private route: ActivatedRoute, private router: Router, injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.log.trace('init auth');
    this.route.queryParams.subscribe(p => {
      setTimeout(() => {
        this.handleJwt(p);
      }, 500);
    });
  }

  private handleJwt(p: Params) {
    this.jwt = p['jwt'];
    if (this.jwt) {
      this.log.trace('token jwt', this.jwt);
      const u = this.authSrv.setToken(this.jwt);
      this.log.trace('auth-user', u);
      if (u) {
        window.close();
      }
    } else {
      this.handlePublic();
    }
  }

  private handlePublic() {
    this.router.navigate([PUBLIC_HOME_PATH]);
  }
}
